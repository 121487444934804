


























import { Component, Vue } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import PrimaryButton from '@/components/common/PrimaryButton.vue'
import Title from '@/components/common/page-layout/Title.vue'
import i18n from '../../i18n'

@Component({
  components: {
    PageLayout,
    ContentArea,
    PrimaryButton,
    Title
  }
})

export default class LanguagerSwitcher extends Vue {
  private selectedItem = ''

  private selectedLocale (item:string):void {
    this.selectedItem = item
    const oldselected = localStorage.getItem('locale') as string

    localStorage.setItem('locale', item)
    if (oldselected !== item) {
      this.$router.push('/' + item + '/language')
      if (item !== null) {
        if (i18n.locale !== item) {
          i18n.locale = item
        }
      }
    }
  }

  private submit (): void {
    this.$store.dispatch('video/initialize')
    this.$router.push({ name: 'location' })
  }

  private created (): void {
    this.selectedItem = localStorage.getItem('locale') as string
    if (window.innerWidth < 767) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  private goBack (): void {
    // this.$router.go(-1)
    this.$router.push({ name: 'splash' })
  }
}
